* {
  flex-shrink: 0;
}

html, body {
    height: 100%;
}

body {
    display: flex;
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    font-weight: 600;  /* semi-bold */
    color: #808080;
    background-color: #F2F3F8;
}

#root {
    display: flex;
    flex: 1;
    flex-direction: column;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

button {
    font-size: 12px;
    font-family: 'Montserrat', sans-serif;
    color: #808080;
    font-weight: 600;
    cursor: pointer;
}

input {
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
}
.ReactModal__Overlay {
    opacity: 0;
    transform: translateX(-100px);
    transition: all 250ms ease-in-out;
    z-index: 100;
  }
  
  .ReactModal__Overlay--after-open {
    opacity: 1;
    transform: translateX(0px);
  }
  
  .ReactModal__Overlay--before-close {
    opacity: 0;
    transform: translateX(-100px);
  }

  .ReactModal__Body--open {
    overflow: hidden;
  }

  .custom-dots {
    display: block;
    width: 100%;
    padding: 0;
    margin-top: 1em;
    list-style: none;
    text-align: center;
  }
  
  .custom-dots li {
    position: relative;
  
    display: inline-block;
  
    width: 20px;
    height: 20px;
    margin: 0 2px;
    padding: 0;
  
    cursor: pointer;
  }
  
  .custom-dots li button {
    font-size: 0;
    line-height: 0;
  
    display: block;
  
    width: 20px;
    height: 20px;
    padding: 5px;
  
    cursor: pointer;
  
    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
  }
  
  .custom-dots li button:hover,
  .custom-dots li button:focus {
    outline: none;
  }
  
  .custom-dots li button:hover:before,
  .custom-dots li button:focus:before {
    opacity: 1;
  }
  
  .custom-dots li button:before {
    font-family: 'slick';
    font-size: 60px;
    line-height: 20px;
  
    position: absolute;
    top: 0;
    left: 0;
  
    width: 20px;
    height: 20px;
  
    content: '•';
    text-align: center;
  
    opacity: 1;
    color: #DFE3E7;
  
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .custom-dots li.carousel-dots-active button:before {
    opacity: 1;
    color: #B40000;
  }
  
  .Carousel .carousel-initialized.scrolling .CustomArrow {
    display: none;
  }

  .rc-tooltip.rc-tooltip-zoom-enter,
.rc-tooltip.rc-tooltip-zoom-leave {
  display: block;
}
.rc-tooltip-zoom-enter,
.rc-tooltip-zoom-appear {
  opacity: 0;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.18, 0.89, 0.32, 1.28);
  animation-play-state: paused;
}
.rc-tooltip-zoom-leave {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.6, -0.3, 0.74, 0.05);
  animation-play-state: paused;
}
.rc-tooltip-zoom-enter.rc-tooltip-zoom-enter-active,
.rc-tooltip-zoom-appear.rc-tooltip-zoom-appear-active {
  animation-name: rcToolTipZoomIn;
  animation-play-state: running;
}
.rc-tooltip-zoom-leave.rc-tooltip-zoom-leave-active {
  animation-name: rcToolTipZoomOut;
  animation-play-state: running;
}
@keyframes rcToolTipZoomIn {
  0% {
    opacity: 0;
    transform-origin: 50% 50%;
    transform: scale(0, 0);
  }
  100% {
    opacity: 1;
    transform-origin: 50% 50%;
    transform: scale(1, 1);
  }
}
@keyframes rcToolTipZoomOut {
  0% {
    opacity: 1;
    transform-origin: 50% 50%;
    transform: scale(1, 1);
  }
  100% {
    opacity: 0;
    transform-origin: 50% 50%;
    transform: scale(0, 0);
  }
}
.rc-tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  visibility: visible;
  font-size: 12px;
  line-height: 1.5;
  opacity: 1;
}
.rc-tooltip-hidden {
  display: none;
}
.rc-tooltip-placement-top,
.rc-tooltip-placement-topLeft,
.rc-tooltip-placement-topRight {
  padding: 5px 0 9px 0;
}
.rc-tooltip-placement-right,
.rc-tooltip-placement-rightTop,
.rc-tooltip-placement-rightBottom {
  padding: 0 5px 0 9px;
}
.rc-tooltip-placement-bottom,
.rc-tooltip-placement-bottomLeft,
.rc-tooltip-placement-bottomRight {
  padding: 9px 0 5px 0;
}
.rc-tooltip-placement-left,
.rc-tooltip-placement-leftTop,
.rc-tooltip-placement-leftBottom {
  padding: 0 9px 0 5px;
}
.rc-tooltip-inner {
  padding: 8px 10px;
  color: #808080;
  text-align: left;
  text-decoration: none;
  background-color: #000;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.17);
  min-height: 34px;
}
.rc-tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
.rc-tooltip-placement-top .rc-tooltip-arrow,
.rc-tooltip-placement-topLeft .rc-tooltip-arrow,
.rc-tooltip-placement-topRight .rc-tooltip-arrow {
  bottom: 4px;
  margin-left: -5px;
  border-width: 5px 5px 0;
  border-top-color: #000;
}
.rc-tooltip-placement-top .rc-tooltip-arrow {
  left: 50%;
}
.rc-tooltip-placement-topLeft .rc-tooltip-arrow {
  left: 15%;
}
.rc-tooltip-placement-topRight .rc-tooltip-arrow {
  right: 15%;
}
.rc-tooltip-placement-right .rc-tooltip-arrow,
.rc-tooltip-placement-rightTop .rc-tooltip-arrow,
.rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
  left: 4px;
  margin-top: -5px;
  border-width: 5px 5px 5px 0;
  border-right-color: #000;
}
.rc-tooltip-placement-right .rc-tooltip-arrow {
  top: 50%;
}
.rc-tooltip-placement-rightTop .rc-tooltip-arrow {
  top: 15%;
  margin-top: 0;
}
.rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
  bottom: 15%;
}
.rc-tooltip-placement-left .rc-tooltip-arrow,
.rc-tooltip-placement-leftTop .rc-tooltip-arrow,
.rc-tooltip-placement-leftBottom .rc-tooltip-arrow {
  right: 4px;
  margin-top: -5px;
  border-width: 5px 0 5px 5px;
  border-left-color: #000;
}
.rc-tooltip-placement-left .rc-tooltip-arrow {
  top: 50%;
}
.rc-tooltip-placement-leftTop .rc-tooltip-arrow {
  top: 15%;
  margin-top: 0;
}
.rc-tooltip-placement-leftBottom .rc-tooltip-arrow {
  bottom: 15%;
}
.rc-tooltip-placement-bottom .rc-tooltip-arrow,
.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow,
.rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
  top: 4px;
  margin-left: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #000;
}
.rc-tooltip-placement-bottom .rc-tooltip-arrow {
  left: 50%;
}
.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow {
  left: 15%;
}
.rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
  right: 15%;
}

.react-autocomplete-input > li {
 cursor: pointer;
 padding: 10px;
 min-width: 100px;
}

.react-autocomplete-input > li.active {
 background-color: #337ab7;
 color: #fff;
}

input,
textarea,
button,
select,
div,
a {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}


